<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>LISTE DES DOSSIERS PME</li>
                </ol>
                <div class="d-flex justify-space-between">
                    <h2>LISTE DES DOSSIERS PME</h2>
                    <div class="mr-3"><router-link class="btn bg-green-darken-4" to="/cga/pmes/create"><i class="bi bi-plus-square-fill" title="Creer un nouveau dossier"></i> NOUVEAU DOSSIER</router-link></div>
                </div>
            </div>
        </section>
        <div class="container">
            <div class="d-flex justify-center gap-3">
                <div v-for="(dossier,index) in dossiers" :key="index" class="card bg-light">
                    <div class="card-body">
                        <router-link :to="{name:'cga_pmes_show',params:{tkn:dossier.token}}">
                            <div class="text-center">
                                <img v-if="dossier.entreprise!=null" class="img img-circle" :src="dossier.entreprise.photo" style="height: 100px; width: 100px;" alt="">
                                <h6 v-if="dossier.entreprise!=null" >{{ dossier.entreprise.name }}</h6>
                                <h6 v-if="dossier.type!=null" >{{ dossier.type.name }}</h6>
                                <small>{{ dossier.created }}</small>
                            </div>
                        </router-link>
                    </div>
                    <div class="card-footer">
                        <a v-if="dossier.status.code==0" @click="ask(dossier.token)"  href="#" class="btn-link btn-xs text-dark">Demander l'évaluation</a>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import axios from "axios";
export default {
    name:"IndexPmes",
    data(){
        return {
            user:this.$store.state.auth.user,
            dossiers:[]
        }
    },
    methods:{
        async ask(token){
            let loader = this.$loading.show();
            await axios.get(this.path+'cga/pme/evaluation/'+token)
                        .then(()=>this.load())
                        .catch((err)=>{
                          console.error(err);
                          if(err.response.status==407){
                              this.toaster.error("Cher partenaire, le solde de votre compte est insuffisant pour créer ce nouveau dossier, nous vous invitons à vous rapprocher du comité exécutif du club IC4A afin de procéder à une recharge après avoir obtenu le paiement de 50% des frais d’évaluation auprès du client final.");
                          }else{
                            this.toaster.error("Echec du serveur!");
                          }
                          
                        })
                        .finally(()=>loader.hide());
        },
        async load(){
            await axios.get(this.path+'cga/pmes')
                        .then((res)=>this.dossiers=res.data)
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>{
            console.log(this.dossiers)
            })
        
    },
    computed:{
    }

}
</script>