<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Dossiers PRESEED</li>
                </ol>
                <h2>{{ dossier.name }}</h2>
                <div class="">
                    <div class="dropdown">
                        <a class="dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Actions</a>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#bmModal">1- EDITER LE MODELE ECONOMIQUE</a></li>
                            <li v-if="dossier.validated_step>0"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#problematiqueModal">2- PROBLEMATIQUE</a></li>
                            <li v-if="dossier.validated_step>1"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#solutionModal">3- SOLUTION PROPOSEE</a></li>
                            <li v-if="dossier.validated_step>2"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#tailleModal">4- TAILLE DU MARCHE</a></li>
                            <li v-if="dossier.validated_step>3"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#concurrenceModal">5- PRESENTATION DE LA CONCURRENCE</a></li>
                            <li v-if="dossier.validated_step>4"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#vaModal">6- PRESENTATION DE LA VALEUR AJOUTEE</a></li>
                            <li v-if="dossier.validated_step>5"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#equipeModal">7- PRESENTATION DE L'EQUIPE</a></li>

                            <li v-if="dossier.validated_step>6"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#etapeModal">8- LES ETAPES DE LA REALISATION</a></li>
                            <li v-if="dossier.validated_step>7"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#objectifsModal">9- OBJECTIFS</a></li>
                            <li v-if="dossier.validated_step>8"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#marketingModal">10- PLAN MARKETING</a></li>
                            <li v-if="dossier.validated_step>9"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#planFinancierModal">11- PLAN FINANCIER PREVISIONNEL</a></li>
                            <li v-if="dossier.validated_step>10"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#besoinsModal">12- BESOINS FINANCIERS</a></li>
                            <li v-if="dossier.validated_step>11"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#teaserModal">13- AFFICHER LE TEASER</a></li>
                            <li v-if="dossier.validated_step>11"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#planModal">14- AFFICHER LE PLAN D'ACTIONS</a></li>
                            <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#newsLetterModal">EDITER UNE NEWS LETTER</a></li>
                            <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#bpModal">CHARGER LE BUSINESS PLAN</a></li>
                            <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#pdModal">CHARGER LE PITCH DECK</a></li>
                            <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#docModal">CHARGER UN DOCUMENT</a></li>
                            <li><router-link class="dropdown-item" :to="{name:'owner_preseed_update_infos', params: {tkn: dossier.token}}">EDITER LES INFOS DU DOSSIER</router-link></li>
                            <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#audioFrModal">PITCH EN FRANCAIS</a></li>
                            <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#audioEnModal">PITCH EN ANGLAIS</a></li>
                            <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#audioLocaleModal">PITCH DANS UNE LANGUE LOCALE</a></li>
                            <li><router-link class="dropdown-item" :to="{name:'owner_plan', params: {tkn: dossier.token}}">PLAN D'ACTION</router-link></li>
                            <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#postModal">EDITER UN POST</a></li>
                            <li><a v-if="dossier.status.code==0" @click="ask()" class="dropdown-item" href="#" type="button">Demander une evaluation</a></li>

                        </ul>
                    </div>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="row">
                    <div id="side1" class="col-md-12 col-sm-12" >
                        <div class="card" style="background:#f7f7f7;">
                            <div class="card-body">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-12">
                                            <div style="height: auto; ">
                                                <img style="max-width: 100%; width: fit-content; max-height: 220px; height: fit-content;"  :src="dossier.photo" alt="">
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-12 infos">
                                            <span class="badge badge-success">ETAPE {{ dossier.step }}</span>
                                            <p>PROMOTEUR : <span class="text-bold">{{ dossier.owner?dossier.owner.name:'-' }}</span></p>
                                            <p>CONSULTANT : <span class="text-bold">{{ dossier.consultant?dossier.consultant.name:'-' }}</span></p>
                                            <p>DATE DE CREATION : <span class="value"> {{ dossier.created }}</span></p>
                                            <p><span>PAYS : </span> {{ dossier.pay?dossier.pay.name:'-' }}</p>
                                            <p>SECTEUR :  <span class="text-bold">{{ dossier.secteur?dossier.secteur.name:'-' }}</span></p>
                                            <input type="hidden" id="pl_id" value="{{ dossier.plan_id }}"/>

                                            
                                        </div>
                                        <div class="col-md-4 col-sm-12">
                                            <div class="card bg-success" style="max-height:220px; overflow:scroll;">
                                                <div class="card-body">
                                                    <p>{{ dossier.description }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div id="side2" style="overflow: scroll" class="col-md-12 col-sm-12">
                        <div class="">
                            
                            <div class="">
                            <div class="" id="">
                                
                                <div class="" id="custom-tabs-four-profile" role="tabpanel" aria-labelledby="custom-tabs-four-profile-tab">
                                    <fieldset>
                                        <legend>Cartographie des risques</legend>
                                        <Diagnostic :choices="choices" />
                                    </fieldset>
                                    <fieldset>
                                        <legend>MODELE ECONOMIQUE</legend>
                                        <ModeleEconomique :modele="dossier.modele"></ModeleEconomique> 
                                    </fieldset>
                                    <fieldset>
                                        <legend>PRESENTATION DE LA PROBLEMATIQUE</legend>
                                        <div v-html="dossier.presentation_problematique"></div>
                                        <div class="table-responsive">
                                            <table class="table table-sm table-striped table-bordered">
                                                <thead>
                                                    <th>#</th>
                                                    <th>Qui sont les clients cibles ?</th>
                                                    <th>Quelle est la problématique à laquelle ils sont confrontés?</th>
                                                    <th>Où achètent-ils des produits-services pour résoudre cette problématique ?</th>
                                                    <th>A quelle fréquence achètent-ils ces produits-services ?</th>
                                                    <th>En moyenne, à combien achètent ils ces produits-services ?</th>
                                                    <th>Pourquoi achètent-ils ces produits-services en particulier et pas un autre ?</th>
                                                </thead>
                                                <tbody>
                                                        <tr v-for="(segment, index) in dossier.segments" :key="index">
                                                            <td>Segment {{ index+1 }}</td>
                                                            <td>{{ segment.clients }}</td>
                                                            <td>{{ segment.problematique }}</td>
                                                            <td>{{ segment.approvisionnement }}</td>
                                                            <td>{{ segment.frequence }}</td>
                                                            <td>{{ segment.moyenne }}</td>
                                                            <td>{{ segment.motif }}</td>
                                                        </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </fieldset>
                                    
                                    <hr>
                                        <div>
                                            <fieldset>
                                                <legend>PRESENTATION DE LA SOLUTION</legend>
                                                <div v-html="dossier.presentation_solution"></div>
                                            </fieldset>
                                        </div>
                                        <hr>
                                        <div>
                                            <fieldset>
                                                <legend>TAILLE DU MARCHE</legend>
                                                <div v-html="dossier.presentation_taille_marche"></div>
                                            </fieldset>
                                        </div>
                                        <hr>
                                        <div>
                                            <fieldset>
                                                <legend>CONCURRENCE</legend>
                                                <div v-html="dossier.presentation_concurrence"></div>
                                                <div class="table-responsive">
                                                    <table class="table table-sm table-striped table-bordered">
                                                        <thead>
                                                            <th>#</th>
                                                            <th>Qui sont vos concurrents sur le segment que vous avez ciblé?</th>
                                                            <th>Quel est le produit-service proposé par vos concurrents pour résoudre les problèmes auxquels vos clients sont confrontés? (Avantages et inconvénients)</th>
                                                            <th>Quels sont les canaux de distribution utilisés par chaque concurrent pour acheminer le produit-service vers les clients? Canaux directs et indirects ; (Avantages et inconvénients)</th>
                                                            <th>Quels sont les canaux de communication utilisés par chaque concurrent pour faire connaitre leur produit-service ? (Avantages et inconvénients)</th>
                                                            <th>Quelle est la stratégie mise en place par les concurrents pour pousser les clients à acheter ? (Avantages et inconvénients)</th>
                                                            <th>Quelle est la stratégie mise en place par les concurrents pour fidéliser les clients ? (Avantages et inconvénients)</th>
                                                            <th>Qui sont les fournisseurs de vos concurrents et donnez en une appréciation en terme de qualité/Coût/Quantité/Délai de livraison ?</th>
                                                            <th>Quelle est la disponibilité des produits-services des concurrents (Saisonnière – toute l’année – dans la limite des niveaux de production) (Avantages et inconvénients)</th>
                                                            <th>Chiffres d'affaires</th>
                                                            <th>Charges variables</th>
                                                            <th>Marge brute</th>
                                                            <th>Charges fixes</th>
                                                            <th>Valeur ajoutée</th>
                                                            <th>Salaires</th>
                                                            <th>EBE</th>
                                                        </thead>
                                                        <tbody>
                                                                <tr v-for="(segment,index) in dossier.concurrents" :key="index">
                                                                    <td>Concurrent {{ index+1 }}</td>
                                                                    <td>{{ segment.concurrent }}</td>
                                                                    <td>{{ segment.produit_service }}</td>
                                                                    <td>{{ segment.canaux_distribution }}</td>
                                                                    <td>{{ segment.canaux_communication }}</td>
                                                                    <td>{{ segment.strategie_client }}</td>
                                                                    <td>{{ segment.strategie_fidelisation }}</td>
                                                                    <td>{{ segment.fournisseurs }}</td>
                                                                    <td>{{ segment.disponibilite }}</td>
                                                                    <td>{{ segment.ca }}</td>
                                                                    <td>{{ segment.cv }}</td>
                                                                    <td>{{ segment.mb }}</td>
                                                                    <td>{{ segment.cf }}</td>
                                                                    <td>{{ segment.va }}</td>
                                                                    <td>{{ segment.salaires }}</td>
                                                                    <td>{{ segment.ebe }}</td>
                                                                </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </fieldset>
                                            
                                        </div>
                                        <hr>
                                        <div>
                                            <fieldset>
                                                <legend>VALEUR AJOUTEE</legend>
                                                <div v-html="dossier.presentation_valeur_ajoutee"></div>
                                            </fieldset>
                                        </div>
                                        <hr>
                                        <div>
                                            <fieldset>
                                                <legend>EQUIPE</legend>
                                                <div v-html="dossier.presentation_equipe"></div>
                                                <div v-if="dossier.presentation_equipe" class="pt-2">
                                                    <button class="btn btn-success btn-xs mb-2" data-bs-toggle="modal" data-bs-target="#membreModal">Ajouter un membre</button>
                                                    <div class="d-flex justify-content-center gap-2">
                                                        <div v-for="(membre, index) in dossier.membres" :key="index" class="" style="width: 200px;">
                                                            <Member :member="membre"></Member>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <hr>
                                        <div>
                                            <fieldset>
                                                <legend>ETAPES DE LA REALISATION</legend>
                                                <div v-html="dossier.presentation_etapes_realisation"></div>
                                            </fieldset>
                                        </div>
                                        <hr>
                                        <div>
                                            <fieldset>
                                                <legend>OBJECTIFS</legend>
                                                <div v-html="dossier.presentation_objectifs"></div>
                                            </fieldset>
                                        </div>
                                        <hr>
                                        <div>
                                            <fieldset>
                                                <legend>PLAN MARKETING</legend>
                                                <div v-html="dossier.presentation_marketing"></div>
                                                <table class="table table-sm table-striped table-bordered mt-2">
                                                    <thead>
                                                        <th>#</th>
                                                        <th>GAMME DE PRODUIT</th>
                                                        <th>Prix proposé</th>
                                                        <th>Stratégie de distribution</th>
                                                        <th>Stratégie de communication</th>
                                                    </thead>
                                                    <tbody>
                                                            <tr v-for="(segment, index) in dossier.marketings" :key="index">
                                                                <td>Segment {{ index }}</td>
                                                                <td>{{ segment.gamme }}</td>
                                                                <td>{{ segment.prix }}</td>
                                                                <td>{{ segment.distribution }}</td>
                                                                <td>{{ segment.communication }}</td>
                                                            </tr>
                                                    </tbody>
                                                </table>
                                            </fieldset>
                                        </div>
                                        <hr>
                                        <div class="mb-1" id="planfinancier">
                                            <fieldset>
                                                <legend>PLAN FINANCIER</legend>
                                                <div class="row">
                                                    <div v-if="dossier.resultat" class="col-md-4 col-sm-12">
                                                        <a :href="dossier.resultat">Compte d'exploitation previsionnel</a>
                                                    </div>
                                                    <div v-if="dossier.bilan" class="col-md-4 col-sm-12">
                                                        <a :href="dossier.bilan">Bilan previsionnel</a>
                                                    </div>
                                                    <div v-if="dossier.treso" class="col-md-4 col-sm-12">
                                                        <a :href="dossier.treso">Flux de tresorerie previsionnel</a>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <hr>
                                        <div>
                                           <fieldset>
                                                <legend>BESOINS FINANCIERS</legend>
                                                <div v-html="dossier.presentation_besoins_financiers"></div>
                                           </fieldset>
                                        </div>
                                        <hr>
                                        <div>
                                           <fieldset>
                                                <legend>BESOINS FINANCIERS</legend>
                                                <div v-html="dossier.presentation_besoins_financiers"></div>
                                           </fieldset>
                                        </div>
                                        <hr>
                                        <div>
                                            <fieldset>
                                                <legend>PITCHS</legend>
                                                <div class="d-flex justify-center gap-3">
                                                    <div v-if="dossier.audio_fr_uri!=null">
                                                        <a class="btn btn-sm bg-dark text-white" :href="getAudioFrPath">En francais</a>
                                                    </div>
                                                    <div v-if="dossier.audio_en_uri!=null">
                                                        <a class="btn btn-sm bg-red-darken-4 text-white" :href="getAudioEnPath">En anglais</a>
                                                    </div>
                                                    <div v-if="dossier.audio_locale_uri!=null">
                                                        <a class="btn btn-sm bg-green-darken-4" :href="getAudioLocalePath">En {{ dossier.locale }}</a>
                                                    </div>
                                                </div>
                                           </fieldset>
                                        </div>
                                        <hr>
                                        <div>
                                            <fieldset>
                                                <legend>AUTRES DOCUMENTS</legend>
                                                <div class="d-flex justify-center gap-2">
                                                    <div class="card bg-success" v-if="dossier.business_plan!=null">
                                                        <div class="card-body">
                                                            <a class="text-white" :href="dossier.business_plan">BUSINESS PLAN</a>
                                                        </div>
                                                    </div>
                                                    <div class="card bg-info" v-if="dossier.pitch_deck!=null">
                                                        <div class="card-body">
                                                            <a class="text-white" :href="dossier.pitch_deck">PITCH DECK</a>
                                                        </div>
                                                    </div>

                                                    <div class="card bg-light" v-for="(doc, index) in dossier.docs" :key="index">
                                                        <div class="card-header">
                                                            <button :data="doc.token" @click="deleteDoc($event)" class="btn btn-sm btn-danger">Supprimer</button>
                                                        </div>
                                                        <div class="card-body">
                                                            <a :href="doc.path">{{ doc.name }}</a>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                           </fieldset>
                                        </div>
                                       
                                    <div>
                                    </div>
                                </div>
                  
                            </div>
                            </div>
                            <!-- /.card -->
                        </div>
                    </div>
                </div>
        </div>
        <!-- Modal -->
<div class="modal fade" id="bmModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">LE MODELE ECONOMIQUE</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="bmclose" aria-label="Close"></button>
            </div>
            <div class="modal-body">
               <EditBM :data="modele" @saved="reload" />
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="postModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">NOUVEAU POST</h5>
                <button type="button" class="btn-close btn-xs btn-danger" data-bs-dismiss="modal" id="close2" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form @submit.prevent="savePost" action="">
                            <div class="form-group">
                                <label for="">Titre</label>
                                <input type="text" v-model="post.name" class="form-control">
                            </div>
                            <div class="form-group">
                                <label for="">Message</label>
                                <textarea name="body" id="body" v-model="post.body" class="form-control" cols="30" rows="5"></textarea>
                            </div>
                            <button type="submit" class="btn btn-success btn-sm">Publier</button>
                        </form>
            </div>
        </div>
    </div>
</div>
<Problematique :dossier="dossier" @ended="reload" />
<Solution :dossier="dossier" @ended="reload" />
<Taille :dossier="dossier" @ended="reload" />
<Concurrence :dossier="dossier" @ended="reload" />
<Va :dossier="dossier" @ended="reload" />
<Equipe :dossier="dossier" @ended="reload" />
<Membre :dossier="dossier" @ended="reload" />
<Etapes :dossier="dossier" @ended="reload" />
<Objectifs :dossier="dossier" @ended="reload" />
<Marketing :dossier="dossier" @ended="reload" />
<Besoins :dossier="dossier" @ended="reload" />
<PlanFinancier :dossier="dossier" @ended="reload" />
<PeachFr :dossier="dossier" @ended="reload" />
<PeachEn :dossier="dossier" @ended="reload" />
<PitchLocale :langues="langues" :dossier="dossier" @ended="reload" />
<BusinessPlan :dossier="dossier" @ended="reload" />
<PitchDeck :dossier="dossier" @ended="reload" />
<Doc :dossier="dossier" @ended="reload" />
<EditNewsLetter :dossier="dossier" @ended="reload" />
</main>
</template>
<script>
import axios from "axios";
import Problematique from "./Problematique.vue";
import Solution from "./Solution.vue";
import Taille from "./Taille.vue";
import Concurrence from "./Concurrence.vue";
import Va from "./Va.vue";
import Equipe from "./Equipe.vue";
import Membre from "./Membre.vue";
import Etapes from "./Etapes.vue";
import Objectifs from "./Objectifs.vue";
import Marketing from "./Marketing.vue";
import Besoins from "./BesoinsFinanciers.vue";
import PlanFinancier from "./PlanFinancier.vue";
import Diagnostic from "../../Includes/Diagnostic.vue";
import { createToaster } from "@meforma/vue-toaster";
import EditBM from "../../Includes/EditBM.vue";
import ModeleEconomique from "../../Includes/ModeleEconomique.vue";
import Member from "../../Includes/Member.vue";
import PeachFr from "./PeachFr.vue";
import PeachEn from "./PeachEn.vue";
import PitchLocale from "./PeachLocale.vue";
import EditNewsLetter from "../../Includes/EditNewsLetter.vue";
import Doc from "./Doc.vue";
import BusinessPlan from "./BusinessPlan.vue";
import PitchDeck from "./PitchDeck.vue";


export default {
    name:"IndexPreseed",
    props: ['tkn'],
    components:{
        Diagnostic,
        EditBM,
        Problematique,
        Solution,
        Taille,
        Concurrence,
        Va,
        Equipe,
        Membre,
        Etapes,
        Objectifs,
        Marketing,
        Besoins,
        PlanFinancier,
        ModeleEconomique,
        Member,
        PeachFr,
        PeachEn,
        PitchLocale,
        Doc,
        PitchDeck,
        BusinessPlan,
        EditNewsLetter,
    },
    data(){
        return {
            user:this.$store.state.auth.user,
            dossier:{},
            modele:{},
            choices:[],
            toaster: createToaster({ position:'top-right'}),
            consultant_id:0,
            componentKey: 0,
            post:{},
            langues:[],
        }
    },
    methods:{
        async ask(){
            let loader = this.$loading.show();
            await axios.get(this.path+'owner/preseeds/evaluation/'+this.tkn)
                        .then(()=>this.reload())
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        setConsultant(event){
            console.log(event)
            this.consultant_id = event.target.value;
        },
        endpb(){
            this.reload();
            document.getElementById('closepb').click();
        },
        async savePost(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            this.post.preseed_id = this.dossier.id;
            await axios.post(this.path+'owner/preseed/post',this.post)
                        .then(()=>{
                            this.reload();
                        })
                        .catch(()=>{
                            this.toaster.error("Cette operation a echoue !!!");
                        })
                        .finally(()=>{
                            loader.hide();
                            document.getElementById('close2').click();
                        });
        },
        forceRender() {
            this.componentKey += 1;
        },

        async deleteDoc(event){
            let token = event.target.getAttribute('data');
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.post(this.path+'owner/dossier/doc/remove',{token:token})
                        .then(()=>{
                            this.toaster.success("Suppression du document !!!");
                            this.load();
                        })
                        .catch((err)=>{
                            this.toaster.error("Echec de suppression du document !!!");
                            console.error(err);
                        })
                        .finally(()=>{loader.hide()});
        },
        
        setData(data){
            this.choices = data.choices;
            this.dossier = data.dossier;
            this.modele = data.dossier.modele;
            console.log(this.modele);
        },
        async load(){
            let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    //onCancel: this.onCancel,
                });
                await axios.get(this.path+'owner/preseeds/'+this.tkn)
                            .then((res)=>{
                                this.setData(res.data);
                                this.loadLocales();
                            })
                            .catch((err)=>console.error(err))
                            .finally(()=>loader.hide());
        },
        async loadLocales(){
            await  axios.get(this.path+'langues')
                .then((res)=>{
                    console.log(res.data)
                    this.langues = res.data;
                })
                .catch((err)=>console.log(err))
        },
        async reload(){
            await axios.get(this.path+'owner/preseeds/'+this.tkn)
                        .then((res)=>{
                            this.setData(res.data)
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        //console.log(this.solvable);
        this.load().then(()=>console.log(this.dossier))
        
    },
    computed:{
        solvable: function(){
            return this.$store.state.auth.entreprise.step_cost_preseed<this.$store.state.auth.user.solde;
        },
        getAudioFrPath(){
            return this.audio_base+this.dossier.audio_fr_uri;
        },
        getAudioEnPath(){
            return this.audio_base+this.dossier.audio_en_uri;
        },
        getAudioLocalePath(){
            return this.audio_base+this.dossier.audio_locale_uri;
        }
    }

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: var(--bs-nav-pills-link-active-color);
        background-color: #3d9970;
    }
    a {
        color: #3d9970;
        
    }
    .infos p{
        margin-bottom: 2px;
        font-size: smaller;
    }
    .table thead th{
        font-size: smaller;
    }
    fieldset{
    padding: 15px;
    border: 1px #b9b9b9 solid;
    border-radius: 5px;
    margin: 10px 0;
}

legend{
    font-weight: 700;
    font-size: 11px;
    font-family: "segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #000000;
    padding: 5px;
    width: auto;
    float: none;
}
</style>