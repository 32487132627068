<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Reponses aux questions</li>
                </ol>
                <h2>{{ form.investisseur.name }}</h2>
                <div class="">
                    <div class="dropdown">
                        <a class="dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Actions</a>
                        <ul class="dropdown-menu">
                        </ul>
                    </div>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div>
                <div style="margin-top: 1rem;" v-for="(reponse,index) in form.reponses" :key="index" class="card">
                    <div class="card-body">
                        <p>{{ reponse.question.name }}</p>
                        <p class="text-danger text-bold">{{ reponse.reponse }}</p>
                    </div>
                </div>
            </div>
        </div>
    </main>

</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"ShowForm",
    props: ['tkn'],
    components:{
    },
    data(){
        return {
            user:this.$store.state.auth.user,
            form:{},
            toaster: createToaster({ position:'top-right'}),

        }
    },
    methods:{
        async load(){
            let loader = this.$loading.show();
            await axios.get(this.path+'consultant/deal/form/'+this.tkn)
                        .then((res)=>{this.form=res.data})
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
    },

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: var(--bs-nav-pills-link-active-color);
        background-color: #3d9970;
    }
    a {
        color: #3d9970;
        text-decoration: none;
        
    }
    .infos p{
        margin-bottom: 2px;
        font-size: smaller;
    }
    .table thead th{
        font-size: smaller;
    }
</style>