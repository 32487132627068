import ConsultantDashboard from '@/components/Consultant/Dashboard.vue';
// Composants du consultants
import ConsultantPreseedIndex from "@/components/Consultant/Preseeds/Index.vue"
import ConsultantPreseedShow from '@/components/Consultant/Preseeds/Show.vue'
import ConsultantPreseedCreate from '@/components/Consultant/Preseeds/Create.vue'
import ConsultantSeedIndex from "@/components/Consultant/Seeds/Index.vue"
import ConsultantSeedShow from '@/components/Consultant/Seeds/Show.vue'
import ConsultantPlan from "@/components/Consultant/Plans/Show.vue"
import ConsultantPligne from "@/components/Consultant/Plans/Pligne.vue"

import ConsultantRdvIndex from "@/components/Consultant/Rdvs/Index.vue"
import ConsultantRdvShow from '@/components/Consultant/Rdvs/Show.vue'

import ConsultantDealIndex from "@/components/Consultant/Deals/Index.vue"
import ConsultantDealForm from '@/components/Consultant/Deals/Form.vue'

import ConsultantPorteurIndex from "@/components/Consultant/Porteurs/Index.vue"
import ConsultantPorteurShow from "@/components/Consultant/Porteurs/Show.vue"

export default[
    {
        name: "consultant_dashboard",
        path: '/consultant/dashboard',
        component: ConsultantDashboard,
        meta: {
            title: `Consultant Dashboard`,
            middleware: "auth"
        }
    },
    {
        name: "consultant_porteur_index",
        path: '/consultant/porteurs',
        component: ConsultantPorteurIndex,
        meta: {
            title: `Gestion des entrepreneurs`,
            middleware: "auth"
        }
    },
    {
        name: "consultant_porteur",
        path: '/consultant/porteur/:tkn',
        component: ConsultantPorteurShow,
        props: true,
        meta: {
            title: `Entrepreneur`,
            middleware: "auth"
        }
    },
    {
        name: "consultant_preseed_index",
        path: '/consultant/preseeds',
        component: ConsultantPreseedIndex,
        meta: {
            title: `Gestion des dossiers preseeds`,
            middleware: "auth"
        }
    },
    {
        name: "consultant_preseed",
        path: '/consultant/preseed/:tkn',
        component: ConsultantPreseedShow,
        props: true,
        meta: {
            title: `Preseed`,
            middleware: "auth"
        }
    },
    {
        name: "consultant_preseed_create",
        path: '/consultant/preseed/create',
        component: ConsultantPreseedCreate,
        meta: {
            title: `Preseed`,
            middleware: "auth"
        }
    },
    {
        name: "consultant_seed_index",
        path: '/consultant/seeds',
        component: ConsultantSeedIndex,
        meta: {
            title: `Gestion des dossiers seed`,
            middleware: "auth"
        }
    },
    {
        name: "consultant_seed",
        path: '/consultant/seed/:tkn',
        component: ConsultantSeedShow,
        props: true,
        meta: {
            title: `Seed`,
            middleware: "auth"
        }
    },

    {
        name: "consultant_rdv_index",
        path: '/consultant/rdvs',
        component: ConsultantRdvIndex,
        meta: {
            title: `Gestion des rendez-vous`,
            middleware: "auth"
        }
    },
    {
        name: "consultant_rdv",
        path: '/consultant/rdv/:tkn',
        component: ConsultantRdvShow,
        props: true,
        meta: {
            title: `Rendez-vous`,
            middleware: "auth"
        }
    },

    {
        name: "consultant_deals_index",
        path: '/consultant/deals',
        component: ConsultantDealIndex,
        meta: {
            title: `Deal flow`,
            middleware: "auth"
        }
    },
    {
        name: "consultant_deal_form",
        path: '/consultant/deal/form/:tkn',
        component: ConsultantDealForm,
        props: true,
        meta: {
            title: `Deal flow form`,
            middleware: "auth"
        }
    },

    {
        name: "consultant_plan",
        path: '/consultant/plan/:tkn',
        component: ConsultantPlan,
        props: true,
        meta: {
            title: `Plan d'action`,
            middleware: "auth"
        }
    },
    {
        name: "consultant_pligne",
        path: '/consultant/pligne/:tkn',
        component: ConsultantPligne,
        props: true,
        meta: {
            title: `Action de maitrise`,
            middleware: "auth"
        }
    },
]